import React, { useEffect, useState } from "react";
import Input from "./Input/input";
import ReferralCode from "./ReferralCode/referralCode";
import Select from "./Select/select";
import SlotDate from "./SlotDate/slotDate";

import InEligible from "./InEligible/inEligible";
import "./styles.scss";
import { API_URL } from "../../../../config/config.js";
import { CONVEX_API_URL } from "../../../../config/config.js";
import Specialisations from "./Specialisations/specialisations";
import moment from "moment";
import Whatsapp from "./Whatsapp/whatsapp";
import { navigate } from "gatsby";
import PhoneInputInternational from "../PhoneInput/phoneInput";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { StaticImage } from "gatsby-plugin-image";

let formData = {
  name: "",
  email: "",
  phone: "",
  lead_type: "",
  organization: "",
  profession: "",
  pass_year: "",
  preferredSlot: "",
  specialisation: [],
  opt_in: "",
  program: "Marketing Launchpad Part Time",
  recommendation_code: "",
};

function CSMPartTimeForm({ search }) {
  let params = new URLSearchParams(search);

  let vemail = params.get("email") || null;

  let utmMedium = params.get("utm_medium") || "No Utm Medium";
  let utmSource = params.get("utm_source") || "No Utm Source";
  let utmContent = params.get("utm_content") || "No Utm Content";
  let utmCampaign = params.get("utm_campaign") || "No Utm Campaign";
  let utmPlacement = params.get("utm_placement") || "No Utm Placement";
  let utmTerm = params.get("utm_term") || "No Utm Term";

  let gaID = "No GA.ID";
  let campaignMediumArr = [
    "facebook",
    "facebook_vv",
    "discovery",
    "performance_max",
    "YT_TVA",
    // "search",
    "youtube_tva",
    "direct_campaign",
    "direct_campaigns",
    "social",
    "bing",
    "bing_brand",
    "bing_nonbrand",
    "email",
    "linkedin",
    // "search_brand",
    "search_nonbrand"
  ];
  const [showRecommendation, setShowRecommendation] = useState(true);
  const [visitorLoading, setVisitorLoading] = useState(false);

  const [detectDropOut, setdetectDropOut] = useState(false);

  const [btnClicked, setBtnClicked] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showInEligible, setShowInEligible] = useState(false);

  const [formFields, setFormFields] = useState(formData);
  const [screeningTests, setScreeningTests] = useState([]);
  const [testCourses, setTestCourses] = useState([]);
  const [campaignId, setCampaignId] = useState();
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedCourse, setSelectedCourse] = useState([]);

  const [checkType, setType] = useState(false);
  const [scrollValidation, setScrollValidation] = useState(false);

  const [allFilled, setAllFilled] = useState(false);

  const [wpOptin, setWpOptin] = useState(true);

  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [emailNotExists, setEmailNotExists] = useState(false);
  //const [gclid, setGclid] = useState(params.get("gclid") || "");
  //const [gclsrc, setGclidSrc] = useState(params.get("gclsrc") || "");

  const [gclid, setGclid] = useState(params.get("gclid") || "");
  const [gclsrc, setGclidSrc] = useState(params.get("gclsrc") || "");
  const [wbraid, setWbraid] = useState(params.get("wbraid") || "");
  const [gbraid, setGbraid] = useState(params.get("gbraid") || "");


  useEffect(() => {
    // Twitter conversion tracking event code
    if (window.twq != null) {
      window.twq("event", "tw-octlb-octle", {});
    }

    if (campaignMediumArr.includes(utmMedium)) {
      setShowRecommendation(false);
    }

    syncGclId();
  }, []);

  useEffect(() => {}, [checkType]);

  useEffect(() => {
    const scrollToErr = () => {
      const errElement = document.querySelector(".validation-err");

      if (errElement) {
        errElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    };

    scrollToErr();
  }, [scrollValidation]);

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const res = await fetch(
          `${API_URL}/get_leads_data/by-email?email=${vemail}&program=${formData.program}`
        );

        const data = await res.json();

        formData = {
          ...data,
          lead_type: data.leadType,
          preferredSlot: "",
        };

        setFormFields(formData);

        setVisitorLoading(false);
      } catch (err) {
        console.log("visitor err", err);
        setVisitorLoading(false);
      }
    };

    const getProgramCampaign = async () => {
      try {
        const res = await fetch(
          `${CONVEX_API_URL}/program/mlp_part/campaign/active` //mlp is variable
        );

        const data = await res.json();
        const sortedDates = data.ScreeningTests.sort(function (a, b) {
          return new Date(a.datetime) - new Date(b.datetime);
        });

        setCampaignId(data.id);
        let screenSlot = sortedDates.map((item) => ({
          value: moment(item.datetime).format("MMMM D, h:mm A"),
          day: moment(item.datetime).format("ddd"),
          time: moment(item.datetime).format("h A"),
          date: moment(item.datetime).format("MMM D"),
          id: item.id,
          isDisabled: item.status === "ACTIVE" ? false : true,
        }));

        setScreeningTests(screenSlot);

        const coursesOptions = data.ScreeningTests[0].TestCourses.map(
          (item) => ({
            value: item.name,
            title: item.description,
            id: item.id,
            isDisabled: item.status === "ACTIVE" ? false : true,
            isChecked: true,
          })
        );

        setTestCourses(coursesOptions);
      } catch (err) {
        console.log("visitor err", err);
      }
    };

    getProgramCampaign();

    if (vemail) {
      setVisitorLoading(true);
      getUserDetails();
    }
  }, []);

  const getExpiryRecord = (value) => {
    let expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds
    let expiryDate = new Date().getTime() + expiryPeriod;

    return {
      value: value,
      expiryDate: expiryDate,
    };
  };

  const whatsAppHandle = () => {
    setWpOptin(!wpOptin);

    formData.opt_in = !wpOptin;

    setFormFields(formData);
  };

  const getValuesFn = (data) => {
    if (data.id === "preferredSlot") {
      let optedSlot = screeningTests.find((item) => item.value === data.value);

      setSelectedSlot(optedSlot.id);
    }

    if (data.id === "specialisation") {
      let optedCoursesId = [];

      testCourses.forEach((item) => {
        if (data.value.includes(item.value)) {
          optedCoursesId.push(item.id);
        }
      });
      setSelectedCourse(optedCoursesId);
    }

    setType(!checkType);
    formData[data.id] = data.value;

    if(data.id === "email"){
      formData[data.id] = data.value.toLowerCase();
    }
    
    if (formData.name && formData.email && detectDropOut === false) {
      setdetectDropOut(true);
    }

    if (
      formData.lead_type === "Studying" ||
      formData.lead_type === "Preparing for a Job (Fresher)"
    ) {
      delete formData.organization;
    } else if (data.id === "organization") {
      formData.organization = data.value;
    } else if (
      formData.lead_type !== "Studying" &&
      !formData.hasOwnProperty("organization")
    ) {
      formData.organization = "";
    }

    if (parseInt(formData.pass_year) >= 2024) {
      formData.not_eligible_for_program = true;
      delete formData.form_submitted;
    } else {
      formData.form_submitted = true;
      delete formData.not_eligible_for_program;
    }

    checkAll();

    // for utm campaings

    formData.utmMedium = utmMedium;
    formData.utmCampaign = utmCampaign;
    formData.utmSource = utmSource;
    formData.utmContent = utmContent;
    formData,utmPlacement = utmPlacement;
    formData.utmTerm = utmTerm;
    formData.gaID = gaID;

    setFormFields(formData);
    syncGclId();
  };

  const checkAll = () => {
    const isComplete = [];

    Object.keys(formFields).forEach(function (key) {
      if (formFields[key] === "" && key !== "recommendation_code") {
        isComplete.push(false);
      } else {
        isComplete.push(true);
      }

      if (
        key === "specialisation" &&
        formFields["specialisation"].length === 0
      ) {
        isComplete.push(false);
      } else {
        isComplete.push(true);
      }
    });

    if (isComplete.includes(false)) {
      setAllFilled(false);

      return false;
    }

    setAllFilled(true);

    return true;
  };

  const storeOnClassApi = async () => {
    const res = await fetch(
      `${API_URL}/isUserRegisteredToProgram?email=${formFields.email}&program=${formFields.program}`
    );

    const data = await res.json();

    if (data.message === "You are already registered!") {
      setEmailAlreadyExists(true);
      setBtnLoading(false);
      setShowThankYou(false);
      setShowForm(true);

      return null;
    }

    setEmailAlreadyExists(false);
    //setShowThankYou(true);

    const postData = await fetch(`${API_URL}/program_leads_data`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formFields),
    });
  };

  const submitFn = async () => {
    setScrollValidation(!scrollValidation); //to scroll page where user has not enter data
    setBtnClicked(true);

    const pass = checkAll(); // to check if all the fields are filled

    if (pass) {
      setBtnLoading(true); //enable loading of button
      let countryCode = formatPhoneNumberIntl(formFields.phone).split(" ")[0];
      let phone = formFields.phone.split(countryCode)[1];

      try {
        let leadData = {
          campaign_id: campaignId,
          course_ids: selectedCourse,
          screening_test_id: selectedSlot,
          email: formFields.email,
          // is_drop_off: detectDropOut,
          is_drop_off: false,
          whatsapp_consent: formFields.opt_in === "Yes" ? true : false,
          organization: formFields.organization,
          year_of_completion: formFields.pass_year,
          qualification: formFields.profession,
          role: formFields.lead_type,
          phone: phone,
          country_code: countryCode,
          is_eligible: false,
          gclid: (gclid?.value ?? null) || (wbraid?.value ?? null) || (gbraid?.value ?? null),
          additional_data: {
            gaId: formFields.gaID,
            utmMedium: formFields.utmMedium,
            utmCampaign: formFields.utmCampaign,
            utmPlacement: formFields.utmPlacement,
            utmSource: formFields.utmSource,
            utmContent: formFields.utmContent,
            utmTerm: formFields.utmTerm,
            slotDate: formFields.preferredSlot,
            recommendation_code: formFields.recommendation_code,
            gclid_type: (gclid?.value ? "gclid" : null) || (wbraid?.value ? "wbraid" : null) || (gbraid?.value ? "gbraid" : null),
          },
        };

        if (
          parseInt(formFields.pass_year) < 2024 ||
          formFields.pass_year == "Before 2015"
        ) {
          leadData.is_eligible = true;
        }

        const postLeadData = await fetch(`${CONVEX_API_URL}/lead/create`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(leadData),
        });
        const resData = await postLeadData.json();

        if (resData.message == "Invalid Email ID.") {
          setEmailNotExists(true);
          setBtnLoading(false);

          return null;
        }

        if (resData.message == "Invalid Email ID.") {
          setEmailNotExists(true);
          setBtnLoading(false);

          return null;
        }

        if (resData.message == "Invalid Email ID.") {
          setEmailNotExists(true);
          setBtnLoading(false);

          return null;
        }

        if (resData.message == "Invalid Email ID.") {
          setEmailNotExists(true);
          setBtnLoading(false);

          return null;
        }

        if (resData.message == "Lead already exists.") {
          setEmailAlreadyExists(true);
          setBtnLoading(false);

          return null;
        }

        // await storeOnClassApi();
        setBtnLoading(false);

        if (parseInt(formFields.pass_year) >= 2024) {
          setShowForm(false);
          setShowInEligible(true);
          setShowThankYou(false);
          scrollToMessage("ineligible-screen");
        } else {
          //console.log("thank you");
          //setShowForm(false);
          setShowInEligible(false);
          setShowThankYou(true);
          scrollToMessage("thank-you-screen");

          if (typeof window !== "undefined") {
            if (window.fbq != null) {
              window.fbq("trackCustom", "MLPPartTimeLead");
            }

            // if (window.qp != null) {
            //   console.log("qp ran");
            //   window.qp('init', '7ffe7223ac9f49d697623843ea2adcc2', {'email': formFields.email});
            // }

            if (window.gtag != null) {
              let enhanced_conversion_data = {
                email: formFields.email,
                countryCode: countryCode,
                phone_number: formFields.phone,
                first_name: formFields.name,
                last_name: "",
              };

              window.gtag("config", "AW-819495038");
              window.gtag("event", "conversion", {
                send_to: "AW-819495038/GovHCIzwotYDEP6A4oYD",
                ...enhanced_conversion_data,
              });
              window.gtag("event", "conversion", {
                send_to: "AW-819495038/GovHCIzwotYDEP6A4oYD",
              });
            }
          }
        }
      } catch (err) {
        setBtnLoading(false);
        console.log("err", err);
        setErrorOccured(true);
      }

      // }
    } else {
      // console.log("fill all", formFields)
    }
  };

  const scrollToMessage = (className) => {
    const errElement = document.querySelector(`.${className}`);

    if (errElement) {
      errElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };
  const syncGclId =() => {
    let gclidParam = params.get("gclid");
    let wbraidParam = params.get("wbraid");
    let gbraidParam = params.get("gbraid");
    let gclidRecord = null;
    let wbraidRecord = null;
    let gbraidRecord = null;
    let currGclidFormField = document.getElementById("gclid");
    let currWbraidFormField = document.getElementById("wbraid");
    let currGbraidFormField = document.getElementById("gbraid");
    let gclsrcParam = params.get("gclsrc");
    
    let isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;
    
    if (gclidParam || wbraidParam || gbraidParam && isGclsrcValid) {
      gclidRecord = getExpiryRecord(gclidParam);
      wbraidRecord = getExpiryRecord(wbraidParam);
      gbraidRecord = getExpiryRecord(gbraidParam);
      localStorage.setItem('gclid', JSON.stringify(gclidRecord));
      localStorage.setItem('wbraid', JSON.stringify(wbraidRecord));
      localStorage.setItem('gbraid', JSON.stringify(gbraidRecord));
    }

    let gclid = gclidRecord || JSON.parse(localStorage.getItem('gclid'));
    let wbraid = wbraidRecord || JSON.parse(localStorage.getItem('wbraid'));
    let gbraid = gbraidRecord || JSON.parse(localStorage.getItem('gbraid'));
    let isGclidValid = gclid && new Date().getTime() < gclid.expiryDate || wbraid && new Date().getTime() < wbraid.expiryDate || gbraid && new Date().getTime() < gbraid.expiryDate;

    if (currGclidFormField || currWbraidFormField || currGbraidFormField && isGclidValid) {
      setGclid(gclid);
      setGclidSrc(gclsrc);
      setWbraid(wbraid);
      setGbraid(gbraid);
    }

  };

  return (
    <div className="main-csm-part-form">
      {showForm && (
        <div className="csm-form-content">
          {visitorLoading ? (
            <p>Loading...</p>
          ) : (
            <React.Fragment>
              {/* <form onSubmit={submitFn} noValidate> */}
              {/* <Input 
                  type="hidden" 
                  id="gclid" 
                  name="gclid" 
                  isRequired={false}
                  value={gclid}
                /> */}
              <Input
                type="text"
                name="name"
                placeholder="Name*"
                isRequired={true}
                btnClicked={btnClicked}
                getValuesFn={getValuesFn}
                value={formFields.name}
              />
              <Input
                type="email"
                name="email"
                placeholder="Email*"
                isRequired={true}
                btnClicked={btnClicked}
                getValuesFn={getValuesFn}
                value={formFields.email}
              />
              <PhoneInputInternational
                type="tel"
                name="phone"
                placeholder="Phone Number*"
                isRequired={true}
                btnClicked={btnClicked}
                getValuesFn={getValuesFn}
                value={formFields.phone}
              />

              <Select
                name="lead_type"
                placeholder="What are you doing currently*"
                options={[
                  "Studying",
                  "Working",
                  "Preparing for Job Change",
                  "Preparing for a Job (Fresher)",
                  "Business Owner",
                ]}
                btnClicked={btnClicked}
                getValuesFn={getValuesFn}
                value={formFields.lead_type}
              />

              {/* applicant select group */}

              {formFields.lead_type && (
                <React.Fragment>
                  <Select
                    name="profession"
                    btnClicked={btnClicked}
                    getValuesFn={getValuesFn}
                    value={formFields.profession}
                    placeholder="Degree*"
                    options={[
                      "MBA / PGDM",
                      "BE / BTech",
                      "BMS/BBA/BBS",
                      "B.Com / M.Com",
                      "B.Sc",
                      "LLB - Law",
                      "BHM - Hotel Management",
                      "Other",
                    ]}
                  />

                  <Select
                    name="pass_year"
                    btnClicked={btnClicked}
                    getValuesFn={getValuesFn}
                    value={formFields.pass_year}
                    placeholder="Graduation Year*"
                    options={[
                      "Before 2015",
                      "2016",
                      "2017",
                      "2018",
                      "2019",
                      "2020",
                      "2021",
                      "2022",
                      "2023",
                      "2024",
                      "2025",
                    ]}
                  />
                </React.Fragment>
              )}

              {/* field for all group except studying and Preparing for a Job (Fresher) */}

              {formFields.lead_type &&
                formFields.lead_type !== "Studying" &&
                formFields.lead_type !== "Preparing for a Job (Fresher)" && (
                <Input
                  type="text"
                  name="organization"
                  placeholder="Organization*"
                  isRequired={true}
                  btnClicked={btnClicked}
                  getValuesFn={getValuesFn}
                  value={formData.organization}
                />
              )}
              {screeningTests.length > 0 ? (
                <SlotDate
                  name="preferredSlot"
                  btnClicked={btnClicked}
                  getValuesFn={getValuesFn}
                  slots={screeningTests}
                />
              ) : null}
              <div className="whatsapp_optin mtop-2">
                <label className="optin-container">
                  {/* Send me the admission process & test details on Whatsapp */}
                  <div className="wrapper-check">
                    Get screening test details on{" "}
                    <StaticImage
                      src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m236s74v.webp"
                      alt="whatsapp"
                    />
                  </div>
                </label>
              </div>
              <Whatsapp
                name="opt_in"
                getValuesFn={getValuesFn}
                btnClicked={btnClicked}
                options={["Yes", "No"]}
                checkedValue={formFields.opt_in}
              />

              {testCourses.length > 0 ? (
                <Specialisations
                  name="specialisation"
                  btnClicked={btnClicked}
                  getValuesFn={getValuesFn}
                  slots={testCourses}
                />
              ) : null}

              {showRecommendation && (
                <ReferralCode
                  type="text"
                  name="recommendation_code"
                  placeholder="Enter code"
                  isRequired={false}
                  btnClicked={btnClicked}
                  getValuesFn={getValuesFn}
                />
              )}

              <div className="notice mtop-2">
                Only complete and accurate applications will be processed.
              </div>
              {errorOccured && (
                <div className="alreadyExists mtop-1">
                  !Oops, something went wrong. Please try again later
                </div>
              )}

              {emailNotExists && (
                <div className="alreadyExists mtop-1">
                  Please enter a valid email ID to proceed
                  {/* <a href="mailto:hi@kraftshala.com">hi@kraftshala.com</a> */}
                </div>
              )}

              {emailAlreadyExists && (
                <div className="alreadyExists mtop-1">
                  You have already registered. You can check your email for more
                  details! In case of any confusion, write to us at
                  <a href="mailto:hi@kraftshala.com">hi@kraftshala.com</a>
                </div>
              )}

              {emailNotExists && (
                <div className="alreadyExists mtop-1">
                  Please enter a valid email ID to proceed
                  {/* <a href="mailto:hi@kraftshala.com">hi@kraftshala.com</a> */}
                </div>
              )}
              <div className="mlp-submit">
                {btnLoading ? (
                  <div
                    className={`submit-btn ${
                      allFilled ? "yellow-bg" : "gray-bg"
                    }`}
                  >
                    Loading...
                  </div>
                ) : (
                  <>
                    <button
                      className={`submit-btn ${
                        allFilled ? "yellow-bg" : "gray-bg"
                      }`}
                      type="submit"
                      aria-hidden="true"
                      onClick={submitFn}
                    >
                      Submit
                    </button>
                    <div className="notice-mlp mtop-2">
                      If you prefer a full time program (10AM - 8PM), you can
                      <a
                        href="https://www.kraftshala.com/marketing-launchpad/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        check this out
                      </a>
                    </div>
                  </>
                )}
              </div>
              {/* </form> */}
            </React.Fragment>
          )}
        </div>
      )}

      {!emailAlreadyExists &&
        showThankYou &&
        navigate(
          "/marketing-launchpad/part-time-content-writing-and-social-media-marketing-course/thank-you/",
          {
            state: { formFields, campaignId },
          }
        )}

      {showInEligible && (
        <div className="ineligible-screen">
          <InEligible />
        </div>
      )}
    </div>
  );
}

export default CSMPartTimeForm;
